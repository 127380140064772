// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as PrivacyPolicyScss from "./PrivacyPolicy.scss";

var css = PrivacyPolicyScss;

function PrivacyPolicy(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("button", {
                      children: "Cookie Settings",
                      className: "ot-sdk-show-settings",
                      id: "ot-sdk-btn"
                    }),
                JsxRuntime.jsx("br", {}),
                JsxRuntime.jsx("br", {}),
                JsxRuntime.jsx("div", {
                      id: "ot-sdk-cookie-policy"
                    })
              ],
              className: css.container
            });
}

var make = PrivacyPolicy;

export {
  css ,
  make ,
}
/* css Not a pure module */
