// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PrivacyPolicy from "./PrivacyPolicy.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function PrivacyPolicy__JsBridge$default(props) {
  return JsxRuntime.jsx(PrivacyPolicy.make, {});
}

var $$default = PrivacyPolicy__JsBridge$default;

export {
  $$default as default,
}
/* PrivacyPolicy Not a pure module */
